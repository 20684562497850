<script setup lang="ts">
import { ref, provide, computed } from 'vue'
import { useStorage } from '@vueuse/core'

import { useAppConfigStore } from '@core/store/app'
import { capitalize } from '@cluber/tools-utils'
import { allowMSW } from '@core/config'

import { appConfigInject } from './declarations'

import DevToolStyles from './Styles/DevToolStyles.vue'
import DevToolLocalization from './Localization/DevToolLocalization.vue'
import DevToolGlobals from './Global/DevToolGlobals.vue'
import DevToolPackages from './Packages/DevToolPackages.vue'
import DevToolFeatureFlags from './FeatureFlags/DevToolFeatureFlags.vue'

// ***
// DEFAULTS
// ***
const environment = import.meta.env.VITE_APP_ENV
const menuRef = ref()
const appConfigStorage = useStorage('cluberConfig', {
  isDarkMode: false,
}, localStorage, { mergeDefaults: true })

const appConfig = useAppConfigStore()

provide(appConfigInject, {
  appConfigStorage,
  appConfig,
})

// ***
// OPEN
// ***
const isOpen = ref(false)
const isOpenSubtools = ref(false)
const currentVisibleSection = ref('')

const sectionComponents = {
  global: {
    component: DevToolGlobals,
    name: 'Global',
    icon: 'bi-globe',
  },
  localization: {
    component: DevToolLocalization,
    name: 'Localization',
    icon: 'bi-translate',
  },
  packages: {
    component: DevToolPackages,
    name: 'Packages',
    icon: 'bi-box-seam',
  },
  styles: {
    component: DevToolStyles,
    name: 'Styles',
    icon: 'bi-brush',
  },
  features: {
    component: DevToolFeatureFlags,
    name: 'Features',
    icon: 'bi-bar-chart-steps',
  },
}

function toggleOpen() {
  isOpen.value = !isOpen.value
  if (!isOpen.value) {
    isOpenSubtools.value = false
  }
  currentVisibleSection.value = 'default'
}

function toggleOpenSubtools(kind: 'open' | 'close', currentVisible: string) {
  currentVisibleSection.value = currentVisible.toLowerCase().replaceAll(' ', '')
  if (isOpenSubtools.value && kind === 'open') return
  isOpenSubtools.value = kind === 'open'
}

// ***
// POSITION
// ***
const calculateMenuLeftCornerPosition = computed(() => ({ left: `-${((menuRef.value?.getBoundingClientRect().width || 0) + 129)}px` }))
</script>

<template>
  <div
    ref="menuRef"
    :class="[
      'fixed bottom-0 md:bottom-3 top-0 md:top-[initial] border border-base-300 dark:bg-base-800 bg-white rounded-lg shadow z-[51] md:z-40 px-8 py-5 min-w-[15rem] transition-all',
      isOpen ? 'right-0 md:right-3 left-10 md:left-[initial]' : 'right-[-15rem] ',
    ]"
  >
    <p
      v-if="isOpenSubtools"
      class="cursor-pointer mb-3 text-xs underline"
      @click="toggleOpenSubtools('close', 'default')"
      @keydown.enter="toggleOpenSubtools('close', 'default')"
    >
      <i class="bi bi-arrow-left-short" /> Go back
    </p>
    <h3 class="font-bold text-2xl mb-3">
      {{ (currentVisibleSection === 'default') ? 'Developer tools' : capitalize(currentVisibleSection) }}
    </h3>
    <i
      :class="[
        'cursor-pointer bg-primary-300 bi rounded-l-lg text-white border border-base-200 dark:border-base-800 position absolute p-2 w-[2rem] left-[-2rem] bottom-3 z-[51]',
        isOpen ? 'bi-x-square' : 'bi-tools',
      ]"
      @click="toggleOpen"
      @keydown="() => {}"
    />
    <template v-if="!isOpenSubtools && isOpen">
      <div class="mb-5 text-center bg-base-200 dark:text-black rounded-lg py-2">
        <p class="text-sm">
          Environment mode
        </p>
        <p class="font-bold">
          {{ capitalize(environment) }}
        </p>
      </div>
      <p class="mb-5 text-sm flex items-center justify-center">
        <span
          :class="[
            'rounded-full h-2 w-2  block mr-2',
            allowMSW ? 'bg-success-300' : 'bg-danger-300',
          ]"
        />
        Mocking Service Worker
      </p>
    </template>
    <div
      v-if="!isOpenSubtools"
      class="space-y-2"
    >
      <div
        v-for="section in sectionComponents"
        :key="section.name"
        class="border-2 border-black dark:border-white rounded-md p-5 w-full select-none cursor-pointer text-center"
        @click="toggleOpenSubtools('open', section.name)"
        @keydown="() => {}"
      >
        <p>
          <i
            :class="[
              'bi',
              section.icon,
            ]"
          />
          {{ capitalize(section.name) }}
        </p>
      </div>
    </div>
    <div
      v-if="isOpenSubtools && isOpen"
      :style="calculateMenuLeftCornerPosition"
    >
      <component :is="sectionComponents[currentVisibleSection as keyof typeof sectionComponents].component" />
    </div>
  </div>
</template>

<style>
.subtools {
  @apply absolute bg-white border border-base-700 p-5 dark:bg-base-800;
  width: 23rem;
  height: calc(100vh - 2.4rem);
  top: 2.4rem;
}
</style>
