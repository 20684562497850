<script setup lang="ts">
import {
  ref,
  watch,
  computed,
  onBeforeUnmount,
  nextTick,
  type Ref,
  onMounted,
} from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { CbLogo, CbSkeletonBlock } from '@cluber/carabiner'
import { clone } from 'lodash-es'
import { useDebounceFn } from '@vueuse/core'
import { useApiClubStoreRequest } from '@services/Club/request'

import { RoleKind } from '@libs/fsc/types/user'

import { useGetLegacyPeopleGroupByClub } from '@dashboard/modules/ProfileSelector/composables'
import { useUnreadMessages } from '@dashboard/modules/Messages/composables/useUnreadMessages'

import SupportModal from '@dashboard/components/Support/SupportModal.vue'

import NavSubmenu from './NavSubmenu/NavSubmenu.vue'
import NavItem from './NavItem/NavItem.vue'
import NavUserData from './NavUserData/NavUserData.vue'

import {
  navigationStructureManager,
  navigationStructureGuardian,
  navigationStructureMember,
  lowerNavigation,
} from './NavigationStructure/index'
import { NavigationItem } from './declarations'
import MobileNavigation from './MobileNavigation/MobileNavigation.vue'
// ***
// DEFAULTS
// ***

const DEFAULT_NAV_ITEMS_AMOUNT = 9

const route = useRoute()
const { t } = useI18n()
const { currentUser } = useGetLegacyPeopleGroupByClub()
const { fetchGetClubEnabledFeatures } = useApiClubStoreRequest()

const isManager = computed(() => route.meta.roleKind === RoleKind.Manager)

// ***
// COMMON
// ***

const nav: Ref<HTMLElement | undefined> = ref()
const activeMenus: Ref<string[]> = ref([])

function isActive(id: string): boolean {
  return activeMenus.value.includes(id)
}

function toggleMenu(id: string): void {
  if (isActive(id)) {
    activeMenus.value = activeMenus.value.filter(itemId => itemId !== id)
  } else {
    activeMenus.value.push(id)
  }
}

// ***
// ENABLED FEATURES
// ***
const enabledFeatures = ref()
const isGetEnabledFeaturesFetching = ref(true)

async function requestEnabledClubFeatures() {
  isGetEnabledFeaturesFetching.value = true
  await fetchGetClubEnabledFeatures({ params: { clubHash: route.params.clubHash } })
    .then(({ data }) => {
      if (data) { enabledFeatures.value = data }
      isGetEnabledFeaturesFetching.value = false
    })
    .catch(() => {
      isGetEnabledFeaturesFetching.value = false
      // TODO: Handle error
    })
}

watch(() => route.params.clubHash, (current) => {
  if (current) requestEnabledClubFeatures()
}, { immediate: true })

// ***
// FILTER FEATURES
// ***

function filterNavigationByEnabledFeatures(navigation: NavigationItem[]) {
  // eslint-disable-next-line consistent-return, array-callback-return
  const filteredNavigation = navigation.filter((navItem) => {
    if (navItem.isFeature === undefined) return navItem
    if (navItem.isFeature && enabledFeatures.value?.includes(navItem.id)) {
      return navItem
    }
  })
  return filteredNavigation
}

// ***
// SUBSIDIES
// ***

const processedSubsidiesNavigation = computed(() => {
  const isEmptySubsidies = !currentUser.value?.subsidies || currentUser.value.subsidies.length === 0
  if (isEmptySubsidies) {
    return navigationStructureManager.value
  }

  const subsidiesMenuItem : NavigationItem = {
    id: 'subsidies',
    label: 'Subsidies',
    icon: 'bi bi-bank2',
    submenu: [],
  }

  subsidiesMenuItem.submenu = currentUser.value.subsidies?.map(
    (subsidy) => ({
      id: `subsidies-${subsidy.id}`,
      label: subsidy.name,
      legacyRoute: () => `/clubes/${route.params.clubHash as string}/subvenciones/${subsidy.id}`,
    }),
  )

  // Placed as second item of list
  const subsidiesNavIndex = 1
  return navigationStructureManager.value.toSpliced(subsidiesNavIndex, 0, subsidiesMenuItem)
})

// ***
// MODALS
// ***
const navItemSelected = ref('')
const showModal = ref(false)

const modalByChosenNav = {
  support: SupportModal,
}

const activeModal = computed(() => {
  const modalKey = navItemSelected.value
  if (modalKey in modalByChosenNav) {
    return modalByChosenNav[modalKey as keyof typeof modalByChosenNav]
  }
  return undefined
})

// ***
// UNREAD MESSAGES
// ***
const { unreadMessages } = useUnreadMessages({ shouldPollUnreadMessages: true })

// ***
// NAVIGATION
// ***

function navigationTypeByRole(): NavigationItem[] {
  const navigationTypes = {
    [RoleKind.Member]: navigationStructureMember,
    [RoleKind.Guardian]: navigationStructureGuardian,
    [RoleKind.Manager]: filterNavigationByEnabledFeatures(processedSubsidiesNavigation.value),
  }
  if (!route.meta.roleKind) return []
  return navigationTypes[route.meta.roleKind as keyof typeof navigationTypes]
}

const navigationStructure = computed(() => {
  const navigation = clone(navigationTypeByRole())

  // MESSAGES BADGE
  const messagesNav = navigation.find((item) => item.id === 'messages')

  if (messagesNav) {
    messagesNav.badge = unreadMessages.value && unreadMessages.value > 0
      ? `${unreadMessages.value}`
      : undefined
  }
  return navigation
})

async function handleNavItemClicked(itemSelected: string) {
  navItemSelected.value = itemSelected
  await nextTick()
  showModal.value = navItemSelected.value in modalByChosenNav
}

// ***
// INERT NAVIGATION
// ***
const isInertNavigation = ref(false)

const debouncedToggleInertNavigation = useDebounceFn(() => {
  const navWidth = nav.value?.getBoundingClientRect().width || 0
  isInertNavigation.value = navWidth < 10
}, 100)

// eslint-disable-next-line @typescript-eslint/no-misused-promises
onMounted(() => nav.value?.addEventListener('transitionend', debouncedToggleInertNavigation))

// eslint-disable-next-line @typescript-eslint/no-misused-promises
onBeforeUnmount(() => nav.value?.removeEventListener('transitionend', debouncedToggleInertNavigation))
</script>

<template>
  <nav
    ref="nav"
    :class="[
      'flex flex-col fixed md:relative md:top-0 top-16 left-0 h-[calc(100vh-4rem)] md:min-w-48 md:h-screen transition-all duration-300 z-40',
      'bg-base-100 dark:bg-base-1000 border-r dark:border-base-700 border-base-300 text-secondary-300 dark:text-white',
    ]"
  >
    <MobileNavigation
      :nav="nav"
      :show-modal="showModal"
      @toggle-inert="isInertNavigation = !isInertNavigation"
    />
    <div
      :inert="isInertNavigation"
      class="overflow-y-scroll"
    >
      <NavUserData />
      <ul>
        <div
          v-if="isGetEnabledFeaturesFetching"
          class="my-1"
        >
          <CbSkeletonBlock
            v-for="item in DEFAULT_NAV_ITEMS_AMOUNT"
            :key="item"
            is-loading
            :class="['mx-auto']"
            height="2rem"
            width="90%"
          />
        </div>
        <template v-else>
          <template
            v-for="item in navigationStructure"
            :key="item.label"
          >
            <NavItem
              v-if="item.route || item.legacyRoute || item.action"
              :id="item.id"
              :icon="item.icon"
              :route="item.route"
              :legacy-route="item.legacyRoute"
              :badge="item.badge"
              @nav-item-clicked="handleNavItemClicked"
            >
              {{ t(item.label) }}
            </NavItem>
            <template v-if="item.submenu">
              <NavSubmenu
                :nav-item="item"
                :submenu-items="item.submenu"
                :is-active="isActive(item.id)"
                @toggle="toggleMenu($event)"
              >
                {{ t(item.label) }}
              </NavSubmenu>
            </template>
          </template>
        </template>
      </ul>

      <ul class="mt-auto">
        <template v-if="isManager">
          <template
            v-for="item in lowerNavigation"
            :key="item.label"
          >
            <NavItem
              v-if="item.route || item.action"
              :id="item.id"
              :action="item.action"
              :route="item.route"
              :legacy-route="item.legacyRoute"
              @nav-item-clicked="handleNavItemClicked"
            >
              {{ t(item.label) }}
            </NavItem>
            <template v-if="item.submenu">
              <NavSubmenu
                :nav-item="item"
                :submenu-items="item.submenu"
                :is-active="isActive(item.id)"
                @toggle="toggleMenu($event)"
              >
                {{ t(item.label) }}
              </NavSubmenu>
            </template>
          </template>
        </template>
      </ul>

      <CbLogo class="w-32 p-3 nav-logo" />
      <component
        :is="activeModal"
        v-if="activeModal"
        v-model="showModal"
      />
    </div>
  </nav>
</template>

<style lang="postcss">
nav {
  scrollbar-width: none;
  -ms-overflow-style: none;
  width: 0;

  &::-webkit-scrollbar {
    display: none;
  }

  @media screen(md) {
    @apply w-52;

    &:has(#menu-toggle:checked) {
      @apply w-52;
    }
  }
}

.nav-logo > img {
  width: 100%;
}

</style>
