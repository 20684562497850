<script setup lang="ts">
import {
  onMounted,
  onBeforeMount,
  watch,
  computed,
} from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { CbProvider, CbAlertField } from '@cluber/carabiner'

import { useAppConfigStore } from '@core/store/app'
import { manageUserAuth, useApiAuthStore } from '@core/modules/Auth/store'
import { useApiUserStore } from '@core/modules/User/store'
import { useBrowserTabTitle } from '@libs/fsc/composables/useBrowserTabTitle'
import useGetClubLocale from '@libs/fsc/composables/useGetClubLocale/useGetClubLocale'

import Navigation from '@dashboard/components/Navigation/Navigation.vue'
import DevTools from '@core/components/DevTools/DevTools.vue'
import {
  isProduction,
  notProductionEnvironment,
  isStaging,
  isDevelopment,
} from '@core/utils/env'

import { apiURL } from './config'
import CookieNotice from './components/CookieNotice/CookieNotice.vue'
import ZohoChat from './components/ZohoChat/ZohoChat.vue'

// ***
// DEFAULTS
// ***
const appConfig = useAppConfigStore()
const apiAuthStore = useApiAuthStore()
const apiUserStore = useApiUserStore()
const i18nConfig = useI18n() as ReturnType<typeof useI18n>
const route = useRoute()
const { setLocale } = useGetClubLocale()

// Sets title based on current route
useBrowserTabTitle(route)

onMounted(() => {
  const releaseVersion = import.meta.env.VITE_APP_RELEASE_VERSION
  if (!releaseVersion) return

  const meta = document.createElement('meta')
  meta.name = 'release-version'
  meta.content = releaseVersion

  const { head } = document
  head.insertBefore(meta, head.firstChild)
})

// ***
// USER AUTH
// ***
const { initializeUserAuth } = manageUserAuth()

onBeforeMount(() => {
  initializeUserAuth()
})

const authToken = () => apiAuthStore.$state.postCreateUserToken?.token || ''

onMounted(setLocale)

watch(() => apiUserStore.$state.getUserProfile, (current) => {
  if (current) setLocale()
})

const isNavigationDashboard = computed(() => route.meta.navigationType === 'dashboard')

watch(isNavigationDashboard, (current) => {
  if (current) {
    document.getElementById('app')?.classList.add('flex')
  } else {
    document.getElementById('app')?.classList.remove('flex')
  }
})
</script>

<template>
  <Transition
    name="fade"
    :duration="{
      enter: 100,
      leave: 400,
    }"
  >
    <Navigation v-if="isNavigationDashboard" />
  </Transition>
  <main
    :data-navigation-dashboard="isNavigationDashboard"
    :class="[
      'App-main',
      route.meta.mainClasses,
    ]"
  >
    <div
      v-if="isStaging && !appConfig.$state.isStagingBannerHidden"
      class="fixed top-0 left-0 right-0 z-50 w-6/12 m-auto text-center"
    >
      <div class="inline-flex px-12 py-1 font-bold rounded-sm bg-warning-300">
        <i class="mr-2 bi bi-exclamation-triangle-fill" /> STAGING ENVIRONMENT
      </div>
    </div>
    <CookieNotice v-if="!isDevelopment" />
    <ZohoChat v-if="isProduction" />
    <DevTools v-if="notProductionEnvironment" />
    <router-view v-slot="{ Component }">
      <!-- @vue-ignore There's a typescript error that doesn't allow to pass proper-->
      <CbProvider
        :key="(i18nConfig.locale.value as string)"
        :i18n-config="i18nConfig"
        :auth-token="authToken"
        :api-urls="apiURL"
        :route="route"
      >
        <CbAlertField />
        <Transition
          mode="out-in"
          name="scale"
        >
          <component
            :is="Component"
            :class="{ 'w-full': isNavigationDashboard }"
          />
        </Transition>
      </CbProvider>
    </router-view>
  </main>
</template>

<style lang="postcss">
.App-main {
  width: 100%;
  &[data-navigation-dashboard="true"] {
    @apply mt-16;
  }
}

@media screen(md) {
  .App-main {
    &[data-navigation-dashboard="true"] {
      @apply mt-0;
    }
  }
}

</style>
