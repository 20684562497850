import { ref, watch, onBeforeUnmount } from 'vue'
import { useRoute } from 'vue-router'

import { getUnreadMessages } from '@dashboard/modules/Messages/api'
import { GetUnreadMessages, UnreadMessages } from '@dashboard/modules/Messages/declarations'
import { useApiMessagesStore } from '@dashboard/modules/Messages/store'
import { useGetLegacyPeopleGroupByClub } from '@dashboard/modules/ProfileSelector/composables'

const ONE_SECOND_IN_MS = 1000
const AWAIT_TIME = 120 * ONE_SECOND_IN_MS
const DEBOUNCE_TIME = 1 * ONE_SECOND_IN_MS

let timeoutId: NodeJS.Timeout
let lastInvokeTime : number = 0

export function useUnreadMessages({ shouldPollUnreadMessages } = { shouldPollUnreadMessages: false }) {
  const unreadMessages = ref()
  const route = useRoute()
  const polledClubHash = ref()
  const polledUserId = ref()
  const { currentUser } = useGetLegacyPeopleGroupByClub()

  const { sendRequest, watchRequest } = useApiMessagesStore()

  function requestUnreadMessages() {
    if (!route.params.clubHash || !currentUser.value) return
    const currentTime = Date.now()
    if (currentTime - lastInvokeTime < DEBOUNCE_TIME) return
    if (timeoutId) clearTimeout(timeoutId)

    lastInvokeTime = currentTime
    timeoutId = setTimeout(requestUnreadMessages, AWAIT_TIME)

    sendRequest<GetUnreadMessages>({
      endpointConfig: getUnreadMessages,
      requestConfig: {
        params: {
          clubHash: route.params.clubHash as string,
        },
        headers: {
          Profile: currentUser?.value?.id,
        },
      },
    })
  }

  onBeforeUnmount(() => { if (timeoutId) clearTimeout(timeoutId) })

  watch(() => [route.params.clubHash, currentUser.value?.id], ([currentClubHash, currentUserHash]) => {
    if (!shouldPollUnreadMessages || !currentClubHash || !currentUserHash) return
    if (currentClubHash === polledClubHash.value || currentUserHash === polledUserId.value) return
    if (timeoutId) clearTimeout(timeoutId)
    polledClubHash.value = currentClubHash
    polledUserId.value = currentUserHash
    requestUnreadMessages()
  })

  const { closeSubscription } = watchRequest<UnreadMessages>(getUnreadMessages, {
    onSuccess(data) {
      if (!data) return
      unreadMessages.value = data.unreadMessages
    },
    onError() {
      // TODO
    },
  })

  onBeforeUnmount(closeSubscription)

  return {
    unreadMessages,
    requestUnreadMessages,
  }
}
