import {
  StoreSendRequestError,
} from '@services/errorHandling'

import {
  type ClubStoreShape,
  type ClubInfoExtended,
  type GetClubEnabledFeaturesRequest,
  type GetClubByHashRequest,
  type GetClubByShortNameRequest,
  type GetClubProfilesRequest,
  type GetClubsRequest,
  type GetClubPaymentsMethodsOnboardLinksRequest,
  type GetClubPaymentsOnboardRequest,
  type ClubEnabledFeatures,
  type ClubUserProfile,
  type ClubInfo,
  type ClubPaymentsMethodsOnboardLinks,
  type ClubPaymentsOnboard,
} from '@services/Club/declarations'

import {
  getClubEnabledFeatures,
  getClubByHash,
  getClubByShortName,
  getClubProfiles,
  getClubs,
  getClubPaymentsMethodsOnboardLinks,
  getClubPaymentsOnboard,
} from '@services/Club/api'

import { useApiStore } from '@core/store/apiRequest/apiRequest'
import { createRateLimitedRequest, type RequestOptions } from '@services/utils'

// ***
// COMMON
// ***
export const useApiClubStore = useApiStore<'apiClub', ClubStoreShape>('apiClub')

const serviceName = 'Club'

const missingClubHashError = (caller: string) => new StoreSendRequestError({
  service: serviceName,
  message: 'clubHash param is required but not found',
  caller,
})

// ***
// REQUESTS
// ***

export function useApiClubStoreRequest() {
  const store = useApiClubStore()
  const { sendRequest, watchRequest } = store

  // Now each request becomes much simpler
  async function fetchGetClubEnabledFeatures(
    requestConfig: GetClubEnabledFeaturesRequest,
    options?: RequestOptions,
  ) {
    if (!requestConfig?.params?.clubHash) throw missingClubHashError('fetchGetClubEnabledFeatures')

    return createRateLimitedRequest<GetClubEnabledFeaturesRequest, ClubEnabledFeatures>({
      sendRequest,
      watchRequest,
      endpoint: getClubEnabledFeatures,
      requestConfig,
      serviceName,
      ...options,
    })
  }

  // FETCH GET CLUB BY HASH
  async function fetchGetClubByHash(
    requestConfig: GetClubByHashRequest,
    options?: RequestOptions,
  ) {
    if (!requestConfig?.params?.clubHash) throw missingClubHashError('fetchGetClubByHash')

    return createRateLimitedRequest<GetClubByHashRequest, ClubInfoExtended>({
      sendRequest,
      watchRequest,
      endpoint: getClubByHash,
      requestConfig,
      serviceName,
      ...options,
    })
  }

  // FETCH GET CLUB BY SHORT NAME
  async function fetchGetClubByShortName(
    requestConfig: GetClubByShortNameRequest,
    options?: RequestOptions,
  ) {
    const error = () => new StoreSendRequestError({
      service: serviceName,
      message: 'clubShortName param is required but not found',
      caller: 'fetchGetClubByShortName',
    })

    if (!requestConfig?.params?.clubShortName) throw error()

    return createRateLimitedRequest<GetClubByShortNameRequest, ClubInfoExtended>({
      sendRequest,
      watchRequest,
      endpoint: getClubByShortName,
      requestConfig,
      serviceName,
      ...options,
    })
  }

  // FETCH GET CLUB PROFILES
  async function fetchGetClubProfiles(
    requestConfig: GetClubProfilesRequest,
    options?: RequestOptions,
  ) {
    if (!requestConfig?.params?.clubHash) throw missingClubHashError('fetchGetClubProfiles')

    return createRateLimitedRequest<GetClubProfilesRequest, ClubUserProfile[]>({
      sendRequest,
      watchRequest,
      endpoint: getClubProfiles,
      requestConfig,
      serviceName,
      ...options,
    })
  }

  // FETCH GET CLUBS
  async function fetchGetClubs(requestConfig: GetClubsRequest, options?: RequestOptions) {
    return createRateLimitedRequest<GetClubsRequest, ClubInfo[]>({
      sendRequest,
      watchRequest,
      endpoint: getClubs,
      requestConfig,
      serviceName,
      ...options,
    })
  }

  // FETCH GET CLUB PAYMENTS METHODS ONBOARD LINKS
  async function fetchGetClubPaymentsMethodsOnboardLinks(
    requestConfig: GetClubPaymentsMethodsOnboardLinksRequest,
    options?: RequestOptions,
  ) {
    if (!requestConfig?.params?.clubHash) throw missingClubHashError('fetchGetClubPaymentsMethodsOnboardLinks')

    return createRateLimitedRequest<GetClubPaymentsMethodsOnboardLinksRequest, ClubPaymentsMethodsOnboardLinks>({
      sendRequest,
      watchRequest,
      endpoint: getClubPaymentsMethodsOnboardLinks,
      requestConfig,
      serviceName,
      ...options,
    })
  }

  // FETCH GET CLUB PAYMENTS ONBOARD
  async function fetchGetClubPaymentsOnboard(
    requestConfig: GetClubPaymentsOnboardRequest,
    options?: RequestOptions,
  ) {
    if (!requestConfig?.params?.clubHash) throw missingClubHashError('fetchGetClubPaymentsOnboard')

    return createRateLimitedRequest<GetClubPaymentsOnboardRequest, ClubPaymentsOnboard>({
      sendRequest,
      watchRequest,
      endpoint: getClubPaymentsOnboard,
      requestConfig,
      serviceName,
      ...options,
    })
  }

  return {
    store,
    fetchGetClubEnabledFeatures,
    fetchGetClubByHash,
    fetchGetClubByShortName,
    fetchGetClubProfiles,
    fetchGetClubs,
    fetchGetClubPaymentsMethodsOnboardLinks,
    fetchGetClubPaymentsOnboard,
  }
}
